"use client";

import Image from "next/image";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveRadialBar } from "@nivo/radial-bar";
import { Icon } from "@iconify/react";
import { ResponsiveBar } from "@nivo/bar";
import { BasicTooltip, Tooltip } from "@nivo/tooltip";
import { useLocalStorage } from "usehooks-ts";
import Dashboard from "../components/dashboard";
import Mirror from "@/components/mirror/mirror";
import { AdbContextProvider } from "@/contexts/adbContext";

type Credentials = {
  serial_number: string;
  activation_code: string;
};

type GetSmartAssistEventsSummaryResponse = {
  sa_events_summary: SmartAssistEventsSummaryContainerRow[];
  min_timestamp: string;
  max_timestamp: string;
  success?: string;
  error?: string;
};

export type SmartAssistEventsSummaryContainerRow = {
  clinic_user_id: number;
  user_name: string;
  home_activation_code: string;
  events: SmartAssistEventsSummaryRow[];
};

export type SmartAssistEventsSummaryRow = {
  clinic_user_id: number;
  sa_session_number: string;
  track_name: string;
  stage: string;
  activity_code: string;
  event_summary: any;
  timestamp: number;
  // device_id: string;
  event_id: number;
  user_name: string;
  home_activation_code: string;
  doctor_id: number;
};

export type SmartAssistEventsSummaryData = {
  sa_events_summary: SmartAssistEventsSummaryContainerRow[];
  min_timestamp: string;
  max_timestamp: string;
};

const ChartColors: Record<string, string> = {
  Perfect: "#48D700",
  Good: "#A5D700",
  Fair: "#FFC700",
  Poor: "#FF6A00",
  Default: "#EEEEEE",
};

const getChartColor = (value: number, maxValue: number) => {
  if (value >= maxValue) return ChartColors.Perfect;
  if (value > maxValue * 0.8) return ChartColors.Good;
  if (value > maxValue * 0.5) return ChartColors.Fair;

  return ChartColors.Poor;
};

const TrackColors: Record<string, string> = {
  Vergence: "#22c55e",
  "Anti-Suppression": "#eab308",
  Stereoacuity: "#3b82f6",
  Default: "#6b7280",
};

const TrackColorsHSL: Record<string, string> = {
  Vergence: "hsl(144, 70%, 50%)",
  "Anti-Suppression": "hsl(40, 70%, 50%)",
  Stereoacuity: "hsl(216, 70%, 50%)",
  Default: "hsl(0, 0%, 50%)",
};

const ChartTracks = ["Vergence", "Anti-Suppression", "Stereoacuity"];

export default function Home() {
  const [serialNumber, setSerialNumber] = useState("");
  const [activationCode, setActivationCode] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const [credentials, setCredentials] = useLocalStorage<Credentials[]>(
    "credentials",
    []
  );

  const [fetchedMappings, setFetchedMappings] = useState<{
    [key: string]: {
      name: string;
      color: string;
    };
  } | null>(null);

  const [fetchedData, setFetchedData] =
    useState<GetSmartAssistEventsSummaryResponse | null>(null);

  useEffect(() => {
    const fetchMappings = async () => {
      const response = await fetch(
        "https://www.seevividly.com/api_patient_dashboard/get_activity_templates"
      );
      const data = await response.json();
      setFetchedMappings(data.mappings);
    };

    fetchMappings();
  }, []);

  const [loginOpen, setLoginOpen] = useState(true);

  const fetchData = useCallback(
    async (serialNumber = "", activationCode = "", rememberMe = false) => {
      const response = await fetch(
        "https://www.seevividly.com/api_patient_dashboard/get_sa_events_summary",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            serial_number: serialNumber,
            activation_code: activationCode,
          }),
        }
      );
      const data = await response.json();
      setFetchedData(data);

      if (data.error) {
        if (credentials.length > 0) {
          const newCredentials = credentials.filter((credential) => {
            return credential.serial_number !== serialNumber;
          });
          setCredentials(newCredentials);
        }
      }
      if (data.success) {
        const credsExist = credentials.find(
          (credential) =>
            credential.serial_number === serialNumber &&
            credential.activation_code === activationCode
        );

        if (rememberMe && !credsExist) {
          setCredentials([
            ...credentials,
            {
              serial_number: serialNumber,
              activation_code: activationCode,
            },
          ]);
        }

        if (credsExist && !rememberMe) {
          const newCredentials = credentials.filter((credential) => {
            return credential.serial_number !== serialNumber;
          });
          setCredentials(newCredentials);
        }
      }
    },
    [credentials, setCredentials]
  );

  const [today, setToday] = useState<string>(
    new Date().toISOString().split("T")[0]
  );

  const eventsData = useMemo(() => {
    if (!fetchedData) return [];

    return fetchedData.sa_events_summary.reduce((acc, container) => {
      container.events.forEach((event) => {
        const eventUnixtime = event.event_summary.UnixStart * 1000;
        if (new Date(eventUnixtime) > new Date(today)) return;

        // if (!ChartTracks.includes(event.track_name)) return;

        acc.push(event);
      });
      return acc;
    }, [] as SmartAssistEventsSummaryRow[]);
  }, [fetchedData, today]);

  const [showChartByDate, setShowChartByDate] = useState(false);

  const bumpData = useMemo(() => {
    if (!eventsData) return [];

    return eventsData
      .sort(
        (a, b) => parseInt(a.sa_session_number) - parseInt(b.sa_session_number)
      )
      .reduce((acc, event) => {
        const xVal = showChartByDate
          ? new Date(event.event_summary.UnixStart * 1000)
              .toISOString()
              .split("T")[0]
          : parseInt(event.sa_session_number);
        // events.forEach((event) => {
        const existing = acc.find((d) => d.id === event.track_name);
        if (existing) {
          existing.data.push({
            id: event.sa_session_number,
            x: xVal,
            y: parseFloat(event.stage),
          });
        } else {
          acc.push({
            id: event.track_name,
            data: [
              {
                id: event.sa_session_number,
                x: xVal,
                y: parseFloat(event.stage),
              },
            ],
            color: TrackColorsHSL[event.track_name] || TrackColorsHSL.Default,
          });
        }
        // });
        return acc;
      }, [] as { id: string; color: string; data: { id: string; x: number | string; y: number }[] }[]);
  }, [eventsData, showChartByDate]);

  const SessionsInLastSevenDays = useMemo(() => {
    if (!eventsData) return 0;

    const todayUnix = new Date(today).getTime() / 1000;
    const sevenDaysAgoUnix = todayUnix - 60 * 60 * 24 * 7;

    const sessions = eventsData.reduce((acc, event) => {
      // container.events.forEach((event) => {
      if (
        event.event_summary.UnixStart > sevenDaysAgoUnix &&
        event.event_summary.UnixStart < todayUnix
      ) {
        acc.add(event.sa_session_number);
      }
      // });
      return acc;
    }, new Set<string>());

    return sessions.size;
  }, [eventsData, today]);

  const SessionsInLastThirtyDays = useMemo(() => {
    if (!eventsData) return 0;

    const todayUnix = new Date(today).getTime() / 1000;
    const thirtyDaysAgoUnix = todayUnix - 60 * 60 * 24 * 30;

    // const lastMonthUnix = lastMonth.getTime() / 1000;

    const sessions = eventsData.reduce((acc, event) => {
      // container.events.forEach((event) => {
      if (
        event.event_summary.UnixStart > thirtyDaysAgoUnix &&
        event.event_summary.UnixStart < todayUnix
      ) {
        acc.add(event.sa_session_number);
      }
      // });
      return acc;
    }, new Set<string>());

    return sessions.size;
  }, [eventsData, today]);

  const TrackProgressByLatestSession = useMemo(() => {
    if (!eventsData) return {};

    const map: Record<string, number> = {};

    eventsData
      .sort(
        (a, b) => parseInt(a.sa_session_number) - parseInt(b.sa_session_number)
      )
      .forEach((event) => {
        if (event.sa_session_number in map) return;
        map[event.track_name] = parseFloat(event.stage);
      });

    return map;
  }, [eventsData]);

  const TrackStagesProgressedInLastSevenDays = useMemo(() => {
    if (!eventsData) return {};

    // const today = new Date();
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);

    const lastWeekUnix = lastWeek.getTime() / 1000;

    const map: Record<string, number> = {};

    // find min stage for each track in the last 7 days
    const minStages = eventsData.reduce((acc, event) => {
      // container.events.forEach((event) => {
      // if (!ChartTracks.includes(event.track_name)) return;

      if (event.event_summary.UnixStart > lastWeekUnix) {
        if (event.track_name in acc) {
          acc[event.track_name] = Math.min(
            acc[event.track_name],
            parseFloat(event.stage)
          );
        } else {
          acc[event.track_name] = parseFloat(event.stage);
        }
      }
      // });
      return acc;
    }, {} as Record<string, number>);

    // calculate stages progressed from min stage
    Object.keys(minStages).forEach((track) => {
      map[track] = TrackProgressByLatestSession[track] - minStages[track];
    });

    return map;
  }, [TrackProgressByLatestSession, eventsData, today]);

  const handleSavedProfileChange = (selected: Credentials) => {
    setSerialNumber(selected.serial_number);
    setActivationCode(selected.activation_code);
    setRememberMe(true);

    fetchData(selected.serial_number, selected.activation_code, true);
  };

  return (
    <main className="flex min-h-screen flex-col items-center p-4 md:px-24 md:pt-12 space-y-4 relative pb-32">
      <div className=" items-center text-sm flex space-x-2 fixed -bottom-0 bg-white border-b border-gray-300 rounded-md p-4 z-20 shadow-2xl">
        {/* <div>{today}</div> */}
        <button
          className="px-2 py-2 border border-gray-300 rounded-md text-gray-800 hover:bg-gray-100 flex items-center justify-center"
          onClick={() =>
            setToday(
              new Date(new Date(today).getTime() - 60 * 60 * 24 * 1000)
                .toISOString()
                .split("T")[0]
            )
          }
        >
          <Icon icon="heroicons:chevron-left" className="w-6 h-6" />
        </button>
        <input
          type="date"
          value={today}
          onChange={(e) => {
            // check if date is valid
            if (
              e.target.value &&
              new Date(e.target.value).toString() !== "Invalid Date"
            )
              setToday(new Date(e.target.value).toISOString().split("T")[0]);
          }}
          className="px-4 py-2 border border-gray-300 rounded-md"
        />

        <button
          className="px-2 py-2 border border-gray-300 rounded-md text-gray-800 hover:bg-gray-100 flex items-center justify-center"
          onClick={() =>
            setToday(
              new Date(new Date(today).getTime() + 60 * 60 * 24 * 1000)
                .toISOString()
                .split("T")[0]
            )
          }
        >
          <Icon icon="heroicons:chevron-right" className="w-6 h-6" />
        </button>
      </div>
      <div
        className={`relative w-full flex items-center justify-center space-x-4 mb-8 transition-all duration-300 ${
          fetchedData ? "pt-8 h-16" : "pt-24 h-32"
        }`}
      >
        <Image
          src="/vivid_vision_logo.png"
          alt="Vivid Vision"
          // fill={true}
          objectFit="contain"
          fill
        />
      </div>
      <div
        className={`items-start justify-between text-sm flex flex-col space-y-2 bg-white rounded-2xl ${
          fetchedData ? "px-8 py-4 mt-8" : "px-8 py-8 mt-8"
        } shadow-md w-full`}
      >
        <div
          className={`w-full ${
            !fetchedData ? "max-h-96" : "max-h-0 overflow-hidden"
          } transition-all duration-300`}
        >
          <label className="flex flex-col space-y-1 w-full">
            <span className="text-gray-800 font-semibold">Saved Profiles</span>
            <select
              id="saved_profiles"
              className="px-4 py-2 border border-gray-300 rounded-md"
              onChange={(e) => {
                if (!e.target.value) {
                  setSerialNumber("");
                  setActivationCode("");
                  setFetchedData(null);
                  return;
                }
                const creds = JSON.parse(e.target.value);
                const selected = credentials.find(
                  (credential) =>
                    credential.serial_number === creds.serial_number &&
                    credential.activation_code === creds.activation_code
                );
                if (selected) {
                  handleSavedProfileChange(selected);
                }
              }}
              value={JSON.stringify({
                serial_number: serialNumber,
                activation_code: activationCode,
              })}
            >
              <option value="">New Profile</option>
              {credentials.map((credential) => (
                <option
                  value={JSON.stringify(credential)}
                  key={JSON.stringify(credential)}
                >
                  {credential.serial_number} - {credential.activation_code}
                </option>
              ))}
            </select>
          </label>
          {/* <form className="flex flex-col items-center justify-between space-y-4 w-full mb-8 lg:mb-0"> */}
          <label className="flex flex-col space-y-1 w-full">
            <span className="text-gray-800 font-semibold">Serial Number</span>
            <input
              type="text"
              className="px-4 py-2 border border-gray-300 rounded-md"
              placeholder="Serial Number"
              id="serial_number"
              name="serial_number"
              autoComplete="off"
              value={serialNumber}
              onChange={(e) => setSerialNumber(e.target.value)}
            />
          </label>
          <label className="flex flex-col space-y-1 w-full">
            <span className="text-gray-800 font-semibold">Activation Code</span>
            <input
              type="text"
              className="px-4 py-2 border border-gray-300 rounded-md"
              placeholder="Activation Code (5 characters)"
              id="activation_code"
              name="activation_code"
              autoComplete="off"
              value={activationCode}
              onChange={(e) => setActivationCode(e.target.value)}
            />
          </label>
        </div>
        <div className="flex flex-col md:flex-row w-full">
          {fetchedData ? (
            <div className="flex flex-col space-y-2 w-full">
              <div className="text-gray-800 font-semibold">
                Welcome to the Patient Dashboard
              </div>
              <div className="flex items-center justify-between w-full">
                {activationCode && (
                  <div className="flex items-center space-x-2">
                    <div className="text-gray-800 font-semibold">
                      Activation Code:
                    </div>
                    <div className="text-gray-800">{activationCode}</div>
                  </div>
                )}
                {serialNumber && (
                  <div className="flex items-center space-x-2">
                    <div className="text-gray-800 font-semibold">
                      Serial Number:
                    </div>
                    <div className="text-gray-800">{serialNumber}</div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <label className="flex flex-col space-y-1 w-full">
              <span className="text-gray-800">Remember Me</span>

              <div
                className="border border-gray-300 rounded-md w-9 h-9 cursor-pointer relative"
                onClick={() => setRememberMe(!rememberMe)}
              >
                {rememberMe && (
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-100 rounded-lg w-9 h-9 flex items-center justify-center">
                    <Icon
                      icon="heroicons-solid:check"
                      className="w-8 h-8 text-blue-500"
                    />
                  </div>
                )}
              </div>
            </label>
          )}
          <a
            className="mt-4 mr-4"
            href="http://vividvision.s3.amazonaws.com/Home%20Version%20Patient%20Manual%20V1.8%20PEDIG%20-%202024-03-18.pdf"
            target="_blank"
          >
            <button className="flex items-center gap-x-2 px-4 py-2 font-medium text-blue-500 rounded-md hover:bg-blue-50 h-full w-full cursor-pointer text-center whitespace-nowrap hover:underline">
              <Icon icon="heroicons:book-open" className="w-6 h-6" />
              <div>View Manual</div>
            </button>
          </a>
          {!fetchedData ? (
            <button
              disabled={!serialNumber && !activationCode}
              type="submit"
              className="px-4 py-2 mt-4 text-white bg-blue-500 rounded-md hover:bg-blue-600 md:w-1/2 w-full disabled:opacity-50"
              onClick={() =>
                fetchData(serialNumber, activationCode, rememberMe)
              }
            >
              <div>Log In</div>
            </button>
          ) : (
            <button
              type="submit"
              className="px-4 py-2 mt-4 text-white bg-gray-400 rounded-md hover:bg-gray-500 md:w-1/2 w-full disabled:opacity-50"
              onClick={() => {
                setSerialNumber("");
                setActivationCode("");
                setFetchedData(null);
              }}
            >
              <div>Log Out</div>
            </button>
          )}
          {/* <label className="flex flex-col items-end space-x-2 justify-end w-full">
            <button
              type="submit"
              className="px-4 py-2 mt-4 text-white bg-blue-500 rounded-md hover:bg-blue-600"
              onClick={() =>
                fetchData(serialNumber, activationCode, rememberMe)
              }
            >
              Log In
            </button>
          </label> */}
        </div>
        {/* </form> */}
      </div>

      <div className="fixed bottom-0 bg-black text-white rounded-md p-4 pt-2 z-20 shadow-2xl flex flex-col items-center">
        <div className="">
          <div className="text-center text-sm font-semibold">
            Date Simulator
          </div>
          <div className="text-center text-xs mb-2">
            Simulate Past or Future Dates
          </div>
        </div>
        <div className="items-center text-sm flex space-x-2">
          <button
            className="px-2 py-2 border border-gray-300 rounded-md text-gray-300 hover:bg-gray-800 flex items-center justify-center"
            onClick={() =>
              setToday(
                new Date(new Date(today).getTime() - 60 * 60 * 24 * 1000)
                  .toISOString()
                  .split("T")[0]
              )
            }
          >
            <Icon icon="heroicons:chevron-left" className="w-6 h-6" />
          </button>
          <input
            type="date"
            value={today}
            onChange={(e) => {
              // check if date is valid
              if (
                e.target.value &&
                new Date(e.target.value).toString() !== "Invalid Date"
              )
                setToday(new Date(e.target.value).toISOString().split("T")[0]);
            }}
            className="px-4 py-2 border border-gray-300 rounded-md text-black"
          />

          <button
            className="px-2 py-2 border border-gray-300 rounded-md text-gray-300 hover:bg-gray-800 flex items-center justify-center"
            onClick={() =>
              setToday(
                new Date(new Date(today).getTime() + 60 * 60 * 24 * 1000)
                  .toISOString()
                  .split("T")[0]
              )
            }
          >
            <Icon icon="heroicons:chevron-right" className="w-6 h-6" />
          </button>
        </div>
      </div>

      {fetchedData && (
        <>
          <AdbContextProvider>
            <Mirror />
          </AdbContextProvider>
          {fetchedMappings && eventsData.length > 0 ? (
            <Dashboard
              eventsData={eventsData}
              today={today}
              mappings={fetchedMappings}
            />
          ) : (
            <div className="flex flex-col items-center justify-center space-y-4">
              <div className="text-2xl font-bold text-gray-800">No Data</div>
              <div className="text-gray-800">
                No data available for the selected date
              </div>
            </div>
          )}
        </>
      )}
      <p className="text-sm">
        For technical support call{" "}
        <a className="text-blue-600 hover:underline" href="tel:18778770310">
          1-877-877-0310
        </a>
      </p>
    </main>
  );
}
