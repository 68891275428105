"use client";

import Image from "next/image";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Datum, DatumValue, Point, ResponsiveLine } from "@nivo/line";
import { ResponsiveRadialBar } from "@nivo/radial-bar";
import { Icon } from "@iconify/react";
import { ResponsiveBar } from "@nivo/bar";
import { BasicTooltip, Tooltip } from "@nivo/tooltip";
import { useLocalStorage } from "usehooks-ts";

type Credentials = {
  serial_number: string;
  activation_code: string;
};

type GetSmartAssistEventsSummaryResponse = {
  sa_events_summary: SmartAssistEventsSummaryContainerRow[];
  min_timestamp: string;
  max_timestamp: string;
  success?: string;
  error?: string;
};

export type SmartAssistEventsSummaryContainerRow = {
  clinic_user_id: number;
  user_name: string;
  home_activation_code: string;
  events: SmartAssistEventsSummaryRow[];
};

export type SmartAssistEventsSummaryRow = {
  clinic_user_id: number;
  sa_session_number: string;
  track_name: string;
  stage: string;
  activity_code: string;
  event_summary: any;
  timestamp: number;
  // device_id: string;
  event_id: number;
  user_name: string;
  home_activation_code: string;
  doctor_id: number;
};

export type SmartAssistEventsSummaryData = {
  sa_events_summary: SmartAssistEventsSummaryContainerRow[];
  min_timestamp: string;
  max_timestamp: string;
};

const ChartColors: Record<string, string> = {
  Perfect: "#48D700",
  Good: "#A5D700",
  Fair: "#FFC700",
  Poor: "#FF6A00",
  Default: "#EEEEEE",
};

const getChartColor = (value: number, maxValue: number) => {
  if (value >= maxValue) return ChartColors.Perfect;
  if (value > maxValue * 0.8) return ChartColors.Good;
  if (value > maxValue * 0.5) return ChartColors.Fair;

  return ChartColors.Poor;
};

const TrackColors: Record<string, string> = {
  Vergence: "#22c55e",
  "Anti-Suppression": "#eab308",
  Stereoacuity: "#3b82f6",
  Test: "#6b7280",
  Default: "#6b7280",
};

const TrackColorsHSL: Record<string, string> = {
  Vergence: "hsl(144, 70%, 50%)",
  "Anti-Suppression": "hsl(40, 70%, 50%)",
  Stereoacuity: "hsl(216, 70%, 50%)",
  Test: "hsl(0, 0%, 50%)",
  Default: "hsl(0, 0%, 50%)",
};

const ChartTracks = ["Vergence", "Anti-Suppression", "Stereoacuity"];

const ActivityCodeToMetricsMapping: Record<string, string[]> = {
  // Breaker
  "Br": ["HitPercentage"],
  "Br-dc": ["HitPercentage"],
  // Hoopie
  "Hp": ["TachModePercentage"],
  "Hp-dc": ["TachModePercentage"],
  // Bubbles
  "Bu": ["MeanBestTwentyCorrect"],
  "Bu-sc": ["MeanBestTwentyCorrect"],
  "Bu-dc": ["MeanBestTwentyCorrect"],
  // Ring Runner
  "Rr": ["AllRingsScore"],
  "Rr-dc": ["AllRingsScore"],
  // Pepper Picker
  "Pp": ["PickPercentage"],
  "Pp-dc": ["PickPercentage"],
  // Barnyard Bounce
  "Ba": ["HighestLevel"],
  // Bullseye
  "Be": ["SuccessRate", "BestDisparity"],
  // Step Vergence
  "Vs": ["SuccessRate"],
  // Jump Vergence
  "Vj": ["SuccessRate"],
};

type MetricDefinition = {
  [key: string]: {
    name: string;
    description: string;
    icon: string;
    display: (value: any) => string;
  };
};

function splitCamelCaseToString(input: string) {
  // Insert a space before each uppercase letter except for the first one.
  // The replace() method uses a regular expression to find uppercase letters
  // and adds a space before each found character.
  return input.replace(/([a-z])([A-Z])/g, '$1 $2');
}


const decimalToPercentage = (value: string) => `${Math.round(parseFloat(value) * 1000) / 10}%`;
const absoluteToPercentage = (value: string) => `${Math.round(parseFloat(value) * 10) / 10}%`;

const ActivityCodeToPrimaryMetricMapping: Record<string, MetricDefinition> = {
  // Breaker
  "Br": {
    HitPercentage: {
      name: "Hit Percentage",
      description: "Percentage of hits",
      icon: "heroicons:squares-2x2",
      display: (value) => decimalToPercentage(value),
    },
  },
  "Br-dc": {
    HitPercentage: {
      name: "Hit Percentage",
      description: "Percentage of hits",
      icon: "heroicons:squares-2x2",
      display: (value) => decimalToPercentage(value),
    },
  },
  // Hoopie
  "Hp": {
    TachModePercentage: {
      name: "Tach Mode Percentage",
      description: "Percentage of time in tach mode",
      icon: "solar:basketball-bold",
      display: (value) => decimalToPercentage(value),
    },
  },
  "Hp-dc": {
    TachModePercentage: {
      name: "Tach Mode Percentage",
      description: "Percentage of time in tach mode",
      icon: "solar:basketball-bold",
      display: (value) => decimalToPercentage(value),
    },
  },
  // Bubbles
  "Bu": {
    MeanBestTwentyCorrect: {
      name: "Mean Best Twenty Correct",
      description: "Mean of the best twenty correct",
      icon: "icon-park-outline:soap-bubble",
      display: (value) => parseInt(value) === -1 ? "N/A" : `${value} arcsec`,
    },
  },
  "Bu-sc": {
    MeanBestTwentyCorrect: {
      name: "Mean Best Twenty Correct",
      description: "Mean of the best twenty correct",
      icon: "icon-park-outline:soap-bubble",
      display: (value) => parseInt(value) === -1 ? "N/A" : `${value} arcsec`,
    },
  },
  "Bu-dc": {
    MeanBestTwentyCorrect: {
      name: "Mean Best Twenty Correct",
      description: "Mean of the best twenty correct",
      icon: "icon-park-outline:soap-bubble",
      display: (value) => value,
    },
  },
  // Ring Runner
  "Rr": {
    AllRingsScore: {
      name: "All Rings Score",
      description: "Score of all rings",
      icon: "fa6-solid:jet-fighter",
      display: (value) => decimalToPercentage(value),
    },
  },
  "Rr-dc": {
    AllRingsScore: {
      name: "All Rings Score",
      description: "Score of all rings",
      icon: "fa6-solid:jet-fighter",
      display: (value) => decimalToPercentage(value),
    },
  },
  // Pepper Picker
  "Pp": {
    PickPercentage: {
      name: "Pick Percentage",
      description: "Percentage of picks",
      icon: "game-icons:bell-pepper",
      display: (value) => decimalToPercentage(value),
    },
  },
  "Pp-dc": {
    PickPercentage: {
      name: "Pick Percentage",
      description: "Percentage of picks",
      icon: "game-icons:bell-pepper",
      display: (value) => decimalToPercentage(value),
    },
  },
  // Barnyard Bounce
  "Ba": {
    HighestLevel: {
      name: "Highest Level",
      description: "Highest level reached",
      icon: "fluent-emoji-high-contrast:chicken",
      display: (value) => `lvl ${value}`,
    },
  },
  // Bullseye
  "Be": {
    SuccessRate: {
      name: "Success Rate",
      description: "Percentage of hits",
      icon: "clarity:bullseye-solid",
      display: (value) => absoluteToPercentage(value),
    },
    BestDisparity: {
      name: "Best Disparity",
      description: "Best disparity",
      icon: "clarity:bullseye-solid",
      display: (value) => parseInt(value) === -1 ? "N/A" : `${value} arcsec`,
    },
  },
  // Step Vergence
  "Vs": {
    SuccessRate: {
      name: "Success Rate",
      description: "Percentage of hits",
      icon: "material-symbols:turn-right-sharp",
      display: (value) => decimalToPercentage(value),
    },
  },
  // Jump Vergence
  "Vj": {
    SuccessRate: {
      name: "Success Rate",
      description: "Percentage of hits",
      icon: "material-symbols:swap-vert",
      display: (value) => decimalToPercentage(value),
    },
  },
  // Jump Vergence
  "St": {
    CSDResult: {
      name: "CSD Result",
      description: "Composite Stereo Depth Score",
      icon: "iconoir:depth",
      display: (value) => value,
    },
    StereoacuityResult: {
      name: "Stereoacuity Result",
      description: "Stereoacuity Estimate",
      icon: "iconoir:depth",
      display: (value) => decimalToPercentage(value),
    },
  },
  // Jump Vergence
  "Pt": {
    PrismResult: {
      name: "Prism",
      description: "Prism Estimate",
      icon: "tabler:prism",
      display: (value) => `(${Object.keys(value).map((key) => `${Math.round(value[key] * 100) / 100}`).join(", ")})`,
    },
  },
};

const TrackToDisplayNameMapping: Record<string, string> = {
  Vergence: "Vergence",
  "Anti-Suppression": "Anti-Suppression",
  Stereoacuity: "Stereoacuity",
  "Test-St": "Test",
  "Test-Pt": "Test",
};


export default function Dashboard({
  eventsData,
  today,
  mappings,
}: {
  eventsData: SmartAssistEventsSummaryRow[];
  today: string;
  mappings: {
    [key: string]: {
      name: string;
      color: string;
    }
  };
}) {
  const [showChartByDate, setShowChartByDate] = useState(true);

  const [sessionNumbersSelected, setSessionNumbersSelected] = useState<string[]>([]);

  const ActivitesBySession = useMemo(() => {
    if (!eventsData) return {};

    const activities = eventsData.sort(
      (a, b) => parseInt(a.event_summary.UnixStart) - parseInt(b.event_summary.UnixStart)
    ).reduce((acc, event) => {
      // date should be YYYY-MM-DD HH:MM:SS
      const sessionNumber = parseInt(event.sa_session_number);
      const trackLabel = TrackToDisplayNameMapping[event.track_name] || event.track_name;

      if (!(sessionNumber in acc)) {
        acc[sessionNumber] = {};
      }
      if (!(trackLabel in acc[sessionNumber])) {
        acc[sessionNumber][trackLabel] = [];
      }
      acc[sessionNumber][trackLabel].push(event);

      return acc;
    }
      , {} as { [sessionNumber: string]: { [track: string]: SmartAssistEventsSummaryRow[] } });

    setSessionNumbersSelected(Object.keys(activities).map((sessionNumber) => sessionNumber).slice(-1));


    return activities;

  }, [eventsData]);


  const bumpData = useMemo(() => {
    if (!ActivitesBySession) return [];


    const data = Object.entries(ActivitesBySession).reduce((acc, [sessionNumber, tracks]) => {
      const x = showChartByDate
        ? new Date(parseInt(sessionNumber) * 1000)
        : parseInt(sessionNumber);

      Object.entries(tracks).forEach(([track, events]) => {
        const existing = acc.find((d) => d.id === track);
        const dateRounded = new Date(parseInt(events[0].event_summary.UnixStart) * 1000);
        dateRounded.setHours(0, 0, 0, 0);

        const xValue = showChartByDate ? dateRounded : parseInt(sessionNumber);
        if (existing) {
          existing.data.push({
            id: sessionNumber,
            x: xValue,
            y: parseFloat(events[0].stage),
            eventsBySession: events.reduce((acc, event) => {
              acc[event.sa_session_number] = event;
              return acc;
            }, {} as Record<string, SmartAssistEventsSummaryRow>),
          });
        } else {
          acc.push({
            id: track,
            data: [
              {
                id: sessionNumber,
                x: xValue,
                y: parseFloat(events[0].stage),
                eventsBySession: events.reduce((acc, event) => {
                  acc[event.sa_session_number] = event;
                  return acc;
                }, {} as Record<string, SmartAssistEventsSummaryRow>),
              },
            ],
            color: TrackColorsHSL[track] || TrackColorsHSL.Default,
          });
        }
      });

      return acc;
    }, [] as { id: string; color: string; data: { id: string; x: number | string | Date; y: number; eventsBySession: Record<string, SmartAssistEventsSummaryRow> }[] }[]);


    return data;


    // return eventsData
    //   .sort(
    //     (a, b) => parseInt(a.event_summary.UnixStart) - parseInt(b.event_summary.UnixStart)
    //   )
    //   .reduce((acc, event) => {
    //     const xVal = showChartByDate
    //       ? new Date(event.event_summary.UnixStart * 1000)
    //       : parseInt(event.sa_session_number);
    //     // events.forEach((event) => {
    //     const existing = acc.find((d) => d.id === event.track_name);
    //     if (existing) {
    //       existing.data.push({
    //         id: event.sa_session_number,
    //         x: xVal,
    //         y: parseFloat(event.stage),
    //       });
    //     } else {
    //       acc.push({
    //         id: event.track_name,
    //         data: [
    //           {
    //             id: event.sa_session_number,
    //             x: xVal,
    //             y: parseFloat(event.stage),
    //           },
    //         ],
    //         color: TrackColorsHSL[event.track_name] || TrackColorsHSL.Default,
    //       });
    //     }
    //     // });

    //     return acc;
    //   }, [] as { id: string; color: string; data: { id: string; x: number | string | Date; y: number }[] }[]);
  }, [showChartByDate, ActivitesBySession]);

  const SessionsInLastSevenDays = useMemo(() => {
    if (!eventsData) return 0;

    const todayUnix = new Date(today).getTime() / 1000;
    const sevenDaysAgoUnix = todayUnix - 60 * 60 * 24 * 7;

    const sessions = eventsData.reduce((acc, event) => {
      // container.events.forEach((event) => {
      if (
        event.event_summary.UnixStart > sevenDaysAgoUnix &&
        event.event_summary.UnixStart < todayUnix
      ) {
        acc.add(event.sa_session_number);
      }
      // });
      return acc;
    }, new Set<string>());

    return sessions.size;
  }, [eventsData, today]);

  const SessionsInLastThirtyDays = useMemo(() => {
    if (!eventsData) return 0;

    const todayUnix = new Date(today).getTime() / 1000;
    const thirtyDaysAgoUnix = todayUnix - 60 * 60 * 24 * 30;

    // const lastMonthUnix = lastMonth.getTime() / 1000;

    const sessions = eventsData.reduce((acc, event) => {
      // container.events.forEach((event) => {
      if (
        event.event_summary.UnixStart > thirtyDaysAgoUnix &&
        event.event_summary.UnixStart < todayUnix
      ) {
        acc.add(event.sa_session_number);
      }
      // });
      return acc;
    }, new Set<string>());

    return sessions.size;
  }, [eventsData, today]);

  const TrackProgressByLatestSession = useMemo(() => {
    if (!eventsData) return {};

    const map: Record<string, number> = {};

    eventsData
      .sort(
        (a, b) => parseInt(a.sa_session_number) - parseInt(b.sa_session_number)
      )
      .forEach((event) => {
        if (event.sa_session_number in map) return;
        map[event.track_name] = parseFloat(event.stage);
      });

    return map;
  }, [eventsData]);

  const TrackStagesProgressedInLastSevenDays = useMemo(() => {
    if (!eventsData) return {};

    // const today = new Date();
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);

    const lastWeekUnix = lastWeek.getTime() / 1000;

    const map: Record<string, number> = {};

    // find min stage for each track in the last 7 days
    const minStages = eventsData.reduce((acc, event) => {
      // container.events.forEach((event) => {
      // if (!ChartTracks.includes(event.track_name)) return;

      if (event.event_summary.UnixStart > lastWeekUnix) {
        if (event.track_name in acc) {
          acc[event.track_name] = Math.min(
            acc[event.track_name],
            parseFloat(event.stage)
          );
        } else {
          acc[event.track_name] = parseFloat(event.stage);
        }
      }
      // });
      return acc;
    }, {} as Record<string, number>);

    // calculate stages progressed from min stage
    Object.keys(minStages).forEach((track) => {
      map[track] = TrackProgressByLatestSession[track] - minStages[track];
    });

    return map;
  }, [TrackProgressByLatestSession, eventsData, today]);



  return (
    <>
      {/* <div className="z-10  w-full items-center text-sm">
        <div className="items-start justify-between text-sm flex flex-col space-y-0 bg-white rounded-2xl px-8 py-4 shadow-xl w-full lg:grow lg:min-w-0">
          <div className="flex flex-col mb-3 text-gray-500 font-medium text-lg">Current Session</div>
          <div className="flex flex-col w-full">
            <div className="flex-1 text-left p-2 rounded-lg flex flex-col space-y-1 h-fit" style={{ background: TrackColors["Anti-Suppression"] }}>
              <div className="flex flex-col space-y-2">
              </div>
            </div>
            <div className="flex-1 text-left p-2 rounded-lg flex flex-col space-y-1 h-fit" style={{ background: TrackColors["Stereoacuity"] }}>
              <div className="flex flex-col space-y-2">
              </div>
            </div>
            <div className="flex-1 text-left p-2 rounded-lg flex flex-col space-y-1 h-fit" style={{ background: TrackColors["Vergence"] }}>
              <div className="flex flex-col space-y-2">
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="w-full items-center justify-between text-sm flex flex-col space-y-4 lg:flex-row lg:gap-x-4 lg:items-start lg:justify-between lg:space-y-0">
        <div className="items-start justify-between text-sm flex flex-col space-y-2 bg-white rounded-2xl px-8 py-4 shadow-xl w-full lg:w-fit">
          <div className="flex flex-col">
            <div className="text-lg font-medium text-gray-500">
              Sessions Played
            </div>
            <div className="text-xs font-medium text-gray-800">
              Your goal is to complete 5 sessions/week and 20 sessions/month
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full justify-between items-center">
            <div>
              <div className="relative h-48 w-48 -mb-20">
                <div className="absolute -top-4 left-0 w-full h-full flex flex-col items-center justify-center">
                  <div className="flex items-center space-x-0.5">
                    <div className="text-4xl font-light">
                      {SessionsInLastSevenDays}
                    </div>
                    <div className="text-lg font-normal text-gray-500">/</div>
                    <div className="text-lg font-normal text-gray-500">5</div>
                  </div>
                </div>
                <ResponsiveRadialBar
                  data={[
                    {
                      id: "Sessions Completed",
                      data: [
                        {
                          x: "Last 7 Days",
                          y: Math.min(SessionsInLastSevenDays, 5),
                        },
                      ],
                    },
                  ]}
                  colors={[
                    getChartColor(SessionsInLastSevenDays, 5),
                    "#EEEEEE",
                  ]}
                  innerRadius={0.65}
                  cornerRadius={5}
                  enableLabels={false}
                  enableRadialGrid={false}
                  enableCircularGrid={false}
                  radialAxisStart={{
                    tickSize: 0,
                    tickComponent: (props) => {
                      return <></>;
                    },
                  }}
                  circularAxisOuter={{
                    tickSize: 0,
                    tickComponent: (props) => {
                      return <></>;
                    },
                  }}
                  tracksColor="#EEEEEE"
                  enableTracks
                  startAngle={270}
                  endAngle={270 + 180}
                  maxValue={5}
                />
              </div>
              <div className="group relative">
                <div className="flex space-x-2 items-center justify-center w-full">
                  <Icon
                    icon={
                      SessionsInLastSevenDays < 5
                        ? "heroicons:exclamation-triangle"
                        : "heroicons:check-circle-solid"
                    }
                    className={`w-6 h-6 ${SessionsInLastSevenDays < 5
                      ? SessionsInLastSevenDays < 3
                        ? "text-red-500"
                        : "text-yellow-500"
                      : "text-green-500"
                      }`}
                  />
                  <div className="font-semibold text-gray-500 text-center">
                    in Last Week
                  </div>
                  <Icon
                    icon="mdi:calendar-week"
                    className="w-6 h-6 text-gray-500"
                  />
                </div>
                <div className="absolute">
                  <div className="absolute top-0 left-0 w-48 p-2 bg-gray-500 text-white text-xs rounded-md font-semibold group-hover:opacity-100 opacity-0 transition-opacity duration-200 z-30">
                    Aim to complete 5 sessions per week
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="relative h-48 w-48 -mb-20">
                <div className="absolute -top-4 left-0 w-full h-full flex flex-col items-center justify-center">
                  <div className="flex items-center space-x-0.5">
                    <div className="text-4xl font-light">
                      {SessionsInLastThirtyDays}
                    </div>
                    <div className="text-lg font-normal text-gray-500">/</div>
                    <div className="text-lg font-normal text-gray-500">20</div>
                  </div>
                </div>
                <ResponsiveRadialBar
                  data={[
                    {
                      id: "Sessions Completed",
                      data: [
                        {
                          x: "Last 30 Days",
                          y: Math.min(SessionsInLastThirtyDays, 20),
                        },
                      ],
                    },
                  ]}
                  colors={[
                    getChartColor(SessionsInLastThirtyDays, 20),
                    "#EEEEEE",
                  ]}
                  innerRadius={0.65}
                  cornerRadius={5}
                  enableLabels={false}
                  enableRadialGrid={false}
                  enableCircularGrid={false}
                  radialAxisStart={{
                    tickSize: 0,
                    tickComponent: (props) => {
                      return <></>;
                    },
                  }}
                  circularAxisOuter={{
                    tickSize: 0,
                    tickComponent: (props) => {
                      return <></>;
                    },
                  }}
                  tracksColor="#EEEEEE"
                  enableTracks
                  startAngle={270}
                  endAngle={270 + 180}
                  maxValue={20}
                />
              </div>
              <div className="group relative">
                <div className="flex space-x-2 items-center justify-center w-full">
                  <Icon
                    icon={
                      SessionsInLastSevenDays < 20
                        ? "heroicons:exclamation-triangle"
                        : "heroicons:check-circle-solid"
                    }
                    className={`w-6 h-6 ${SessionsInLastThirtyDays < 20
                      ? SessionsInLastThirtyDays < 11
                        ? "text-red-500"
                        : "text-yellow-500"
                      : "text-green-500"
                      }`}
                  />

                  <div className="font-semibold text-gray-500 text-center">
                    in Last Month
                  </div>
                  <Icon
                    icon="mdi:calendar-month"
                    className="w-6 h-6 text-gray-500"
                  />
                </div>
                <div className="absolute">
                  <div className="absolute top-0 left-0 w-48 p-2 bg-gray-500 text-white text-xs rounded-md font-semibold group-hover:opacity-100 opacity-0 transition-opacity duration-200 z-30">
                    Aim to complete 20 sessions per month
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="items-start justify-between text-sm flex flex-col space-y-0 bg-white rounded-2xl px-8 py-4 shadow-xl w-full lg:grow lg:min-w-0">
          <div className="flex flex-col mb-3">
            <div className="text-lg font-medium text-gray-500">
              Progress Snapshot
            </div>
            <div className="text-xs font-medium text-gray-800">
              The Stage difficulty level that was reached in the last session
            </div>
          </div>
          <div className="items-start justify-between text-sm flex flex-col w-full">
            <div className="text-sm font-semibold text-gray-800">
              Anti-Suppression
            </div>
            <div className="h-6 bg-[#EEEEEE] rounded-[5px]  w-full ">
              <div className="w-full h-full min-w-0 min-h-0">
                <ResponsiveBar
                  data={[
                    {
                      id: "Anti-Suppression",
                      "Anti-Suppression":
                        (TrackProgressByLatestSession["Anti-Suppression"] /
                          10) *
                        100,
                    },
                  ]}
                  keys={["Anti-Suppression"]}
                  colors={[TrackColors["Anti-Suppression"]]}
                  label={(d) => `${d.value}%`}
                  valueFormat={(v) => `${v}%`}
                  theme={
                    {
                      labels: {
                        text: {
                          fill: "white",
                          fontSize: 16,
                          fontWeight: 700,
                        },
                      },
                    } as any
                  }
                  enableGridX={false}
                  enableGridY={false}
                  padding={0}
                  margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                  maxValue={100}
                  borderRadius={5}
                  layout="horizontal"
                />
              </div>
            </div>

            <div className="text-sm font-semibold text-gray-800">
              Stereoacuity
            </div>
            <div className="h-6 bg-[#EEEEEE] rounded-[5px] w-full">
              <div className="w-full h-full min-w-0 min-h-0">
                <ResponsiveBar
                  data={[
                    {
                      id: "Progress",
                      Stereoacuity:
                        (TrackProgressByLatestSession["Stereoacuity"] / 10) *
                        100,
                    },
                  ]}
                  keys={["Stereoacuity"]}
                  colors={[TrackColors.Stereoacuity]}
                  label={(d) => `${d.value}%`}
                  valueFormat={(v) => `${v}%`}
                  theme={
                    {
                      labels: {
                        text: {
                          fill: "white",
                          fontSize: 16,
                          fontWeight: 700,
                        },
                      },
                    } as any
                  }
                  enableGridX={false}
                  enableGridY={false}
                  padding={0}
                  margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                  maxValue={100}
                  borderRadius={5}
                  layout="horizontal"
                />
              </div>
            </div>
            <div className="text-sm font-semibold text-gray-800">Vergence</div>
            <div className="h-6 bg-[#EEEEEE] rounded-[5px] w-full">
              <div className="w-full h-full min-w-0 min-h-0">
                <ResponsiveBar
                  data={[
                    {
                      id: "Progress",
                      Vergence:
                        (TrackProgressByLatestSession["Vergence"] / 10) * 100,
                    },
                  ]}
                  keys={["Vergence"]}
                  colors={[TrackColors.Vergence]}
                  label={(d) => `${d.value}%`}
                  valueFormat={(v) => `${v}%`}
                  theme={
                    {
                      labels: {
                        text: {
                          fill: "white",
                          fontSize: 16,
                          fontWeight: 700,
                        },
                      },
                    } as any
                  }
                  enableGridX={false}
                  enableGridY={false}
                  padding={0}
                  margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                  maxValue={100}
                  borderRadius={5}
                  layout="horizontal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="z-10  w-full items-center justify-between text-sm flex flex-col space-y-4">
        {Object.values(TrackStagesProgressedInLastSevenDays).filter(
          (progress) => progress > 0
        ).length > 0 && (
            <div className="flex space-x-8 items-center pl-3 pr-4 py-2 border-green-400 bg-green-50 border-[0px] rounded-xl">
              <div className="w-16 h-16">
                <Icon
                  icon="heroicons:check-circle-20-solid"
                  className="text-green-400 w-16 h-16"
                />
              </div>
              <div className="flex flex-col text-left justify-center space-y-2">
                <div className="text-xl font-semibold text-gray-800">
                  Making Progress
                </div>
                <div className="text-base text-gray-800 p-2 rounded-md">
                  {Object.entries(TrackStagesProgressedInLastSevenDays)
                    .filter(([track, progress]) => progress > 0)
                    .map(([track, progress]) => (
                      <div key={track} className="flex flex-col space-y-2 items-start md:space-y-0 md:flex-row md:space-x-2 md:items-center">
                        <div key={track} className="flex space-x-2 items-center">
                          <div
                            className="w-3 h-3"
                            style={{
                              background: TrackColors[track],
                            }}
                          ></div>
                          <div className="font-medium">{track}:</div>
                        </div>
                        <div>
                          {progress} {progress > 1 ? "stages " : "stage "}{" "}
                          advanced in the last 7 days
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        {SessionsInLastSevenDays < 5 && SessionsInLastSevenDays > 2 && (
          <div className="flex space-x-8 items-center pl-3 pr-4 py-2 border-yellow-400 bg-yellow-50 border-[0px] rounded-xl">
            <div className="w-16 h-16 -mb-1">
              <Icon
                icon="heroicons:exclamation-triangle-20-solid"
                className="text-yellow-400 w-16 h-16"
              />
            </div>
            <div className="flex flex-col text-left justify-center space-y-2">
              <div className="text-xl font-semibold text-gray-800">
                Low Weekly Sessions
              </div>
              {SessionsInLastSevenDays > 0 ? (
                <div className="text-base text-gray-800">
                  Only {SessionsInLastSevenDays} sessions in the last 7 days.
                  Aim to complete 5 sessions per week.
                </div>
              ) : (
                <div className="text-base text-gray-800">
                  No sessions in the last 7 days. Aim to complete 5 sessions per
                  week.
                </div>
              )}
            </div>
          </div>
        )}
        {SessionsInLastSevenDays < 3 && (
          <div className="flex space-x-8 items-center pl-3 pr-4 py-2 border-red-400 bg-red-50 border-[0px] rounded-xl">
            <div className="w-16 h-16 -mb-1">
              <Icon
                icon="heroicons:exclamation-triangle-20-solid"
                className="text-red-400 w-16 h-16"
              />
            </div>
            <div className="flex flex-col text-left justify-center space-y-2">
              <div className="text-xl font-semibold text-gray-800">
                Very Low Weekly Sessions
              </div>
              {SessionsInLastSevenDays > 0 ? (
                <div className="text-base text-gray-800">
                  Only {SessionsInLastSevenDays} sessions in the last 7 days.
                  Aim to complete 5 sessions per week.
                </div>
              ) : (
                <div className="text-base text-gray-800">
                  No sessions in the last 7 days. Aim to complete 5 sessions per
                  week.
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {/* <div style="pointer-events: none; position: absolute; z-index: 10; top: 0px; left: 0px; transform: translate(-42.875px, -19px);">
        <div style="background: white; color: inherit; font-size: inherit; border-radius: 2px; box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px; padding: 5px 9px;">
          <div style="white-space: pre; display: flex; align-items: center;">
            <span style="display: block; width: 12px; height: 12px; background: rgb(59, 130, 246); margin-right: 7px;"></span>
            <span>
              Stereoacuity - Progress: <strong>20%</strong>
            </span>
          </div>
        </div>
      </div> */}
      <div className="z-10  w-full items-center text-sm">
        <div className="items-start justify-between text-sm flex flex-col space-y-0 bg-white rounded-2xl px-8 py-4 shadow-xl w-full lg:grow lg:min-w-0">
          <div className="flex flex-col items-start justify-center">
            <div className="flex flex-col mb-3">
              <div className="text-lg font-medium text-gray-500">
                Progress Over Time
              </div>
              <div className="text-xs font-medium text-gray-800">
                Track your progress through each of the tracks over time or by
                session
              </div>
            </div>
            <div className="flex space-x-4 items-center">
              <button
                className={`px-2 py-1 rounded-md font-semibold ${showChartByDate
                  ? "bg-gray-500 hover:bg-gray-600 text-white "
                  : " hover:bg-gray-100 text-gray-800"
                  } `}
                onClick={() => setShowChartByDate(true)}
              >
                By Date
              </button>
              <button
                className={`px-2 py-1 rounded-md font-semibold ${!showChartByDate
                  ? "bg-gray-500 hover:bg-gray-600 text-white "
                  : " hover:bg-gray-100 text-gray-800"
                  }`}
                onClick={() => setShowChartByDate(false)}
              >
                By Session
              </button>
            </div>
          </div>
          <div className="w-full h-[500px]">
            <ResponsiveLine
              data={bumpData}
              onClick={(data: any) => {
                const sessions: string[] = Array.from(new Set(data.points.map((p: any) => p.data.id)));
                setSessionNumbersSelected(sessions);
              }}

              sliceTooltip={({ slice }) => {
                const { points } = slice;

                const data = points.map(p => p.data) as {
                  x: DatumValue;
                  xFormatted: string;
                  y: DatumValue;
                  yFormatted: string;
                  id: string;
                  color: string;
                  eventsBySession: Record<string, SmartAssistEventsSummaryRow>;
                }[];

                const sessionsInSlice = Array.from(new Set(data.map(d => d.id)));

                // get events in slice from ActivitesBySession
                const eventsInSliceBySession = sessionsInSlice.reduce((acc, session) => {
                  if (session in ActivitesBySession) {
                    Object.entries(ActivitesBySession[session]).forEach(([track, events]) => {

                      if (!(session in acc)) {
                        acc[session] = {};
                      }
                      if (!(track in acc[session])) {
                        acc[session][track] = [];
                      }
                      acc[session][track].push(...events);



                    });
                  }
                  return acc;
                }, {} as { [session: string]: { [track: string]: SmartAssistEventsSummaryRow[] } });

                return (
                  <div
                    style={{
                      background: "white",
                      color: "inherit",
                      fontSize: "inherit",
                      borderRadius: "2px",
                      boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
                      padding: "5px 9px",
                    }}
                  >
                    <div>
                      {showChartByDate ? (
                        <div className="">
                          <div className="font-semibold">
                            {new Date(points[0].data.x).toLocaleDateString(
                              undefined,
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )}
                          </div>
                          {Object.keys(eventsInSliceBySession).map((session, i) => (
                            <div key={session} className="flex flex-col">
                              <div>Session #{session}</div><div className="text-[0.6rem] text-gray-500">started at {new Date(parseInt(eventsInSliceBySession[session][Object.keys(eventsInSliceBySession[session])[0]][0].event_summary.UnixStart) * 1000).toLocaleTimeString()}</div>
                              <div>
                                {Object.keys(eventsInSliceBySession[session]).map((track) => (
                                  <div key={track} className="flex flex-col">
                                    <div className="flex space-x-2 items-center">
                                      <div
                                        style={{
                                          background: TrackColors[track],
                                        }}
                                        className="w-3 h-3"
                                      />
                                      <div>{track}: <span className="font-semibold">{parseFloat(eventsInSliceBySession[session][track][0].stage)}</span></div>
                                    </div>
                                    <div className="ml-5">
                                      {/* {Object.entries(eventsInSliceBySession[session][track]).map(([i, event]) => (
                                        <div key={i} className="flex space-x-2 justify-between text-xs">
                                          <div className="flex items-center space-x-2">

                                            <div>{mappings[event.activity_code].name}:</div>
                                          </div>
                                          <div>{ActivityCodeToPrimaryMetricMapping[event.activity_code] && [Object.keys(ActivityCodeToPrimaryMetricMapping[event.activity_code])[0]].map((metric) => (
                                            <div key={metric} className="flex space-x-2">
                                              <div>{ActivityCodeToPrimaryMetricMapping[event.activity_code][metric].display(event.event_summary.Metrics[metric])}</div>
                                            </div>
                                          ))}</div>
                                        </div>
                                      ))} */}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <>
                          <div className="font-semibold">
                            Session #{points[0].data.x.toString()}
                          </div>
                          {slice.points.map((point) => (
                            <div
                              key={point.id}
                              className="flex whitespace-pre items-center justify-between space-x-2"
                            >
                              <div className="flex items-center space-x-2">
                                <div
                                  style={{
                                    background: point.color,
                                  }}
                                  className="w-3 h-3"
                                ></div>
                                <div>{point.serieId}:</div>
                              </div>

                              <div>{point.data.y.toString()}</div>
                            </div>
                          ))}
                        </>
                      )}{" "}

                    </div>
                  </div>
                );
              }}
              colors={(d) => d.color}
              margin={{ top: 40, right: 10, bottom: 40, left: 45 }}
              xScale={
                showChartByDate
                  ? {
                    type: "time",
                    format: "native",
                    // useUTC: false,
                    precision: "day",
                    // max: today,
                  }
                  : { type: "linear" }
              }
              xFormat={showChartByDate ? "time:%Y-%m-%d" : ""}
              yScale={{
                type: "linear",
                min: 0,
                max: 10,
              }}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: showChartByDate ? "Date" : "Session #",
                legendOffset: 36,
                legendPosition: "middle",
                format: (d) =>
                  showChartByDate ? new Date(d).toLocaleDateString(
                    undefined,
                    {
                      year: "2-digit",
                      month: "numeric",
                      day: "numeric",
                    }
                  ) : d,
                // tickValues: showChartByDate
                //   ? undefined
                //   : bumpData[0]?.data
                //       .map((d: any) => d.x)
                //       .sort((a, b) => a - b),
              }}
              gridXValues={
                showChartByDate
                  ? undefined
                  : undefined
              }
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Stage",
                legendOffset: -30,
                legendPosition: "middle",
                tickValues: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
              }}
              pointSize={10}
              pointColor={(d: any) => d.color}
              pointLabelYOffset={-12}
              enableSlices="x"
              useMesh={true}
              crosshairType="bottom"
              animate={true}
            />
          </div>
        </div>
      </div>
      {/* <div className="z-10  w-full items-center justify-between text-sm flex flex-col space-y-4">
        Sessions Selected: {sessionNumbersSelected.join(", ")}
      </div> */}
      <div className="z-10  w-full items-center text-sm">
        <div className="items-start justify-between text-sm flex flex-col space-y-0 bg-white rounded-2xl px-8 py-4 shadow-xl w-full lg:grow lg:min-w-0">
          <div className="flex flex-col items-start justify-center">
            <div className="flex flex-col mb-3">
              <div className="text-lg font-medium text-gray-500">
                Session Details
              </div>
              <div className="text-xs font-medium text-gray-800">
                Track your progress through each of the stages over time or by
                session
              </div>
            </div>
            <div className="flex space-x-4 items-center mb-4">
              <button
                className={`px-2 py-1 rounded-md font-semibold ${sessionNumbersSelected.length === 1 && sessionNumbersSelected[0] === Object.keys(ActivitesBySession).slice(-1)[0]
                  ? "bg-gray-500 hover:bg-gray-600 text-white "
                  : " hover:bg-gray-100 text-gray-800"
                  } `}
                onClick={() => setSessionNumbersSelected([Object.keys(ActivitesBySession).map((session) => session).slice(-1)[0]])}
              >
                Last Session
              </button>
              <button
                className={`px-2 py-1 rounded-md font-semibold ${sessionNumbersSelected.length === 0
                  ? "bg-gray-500 hover:bg-gray-600 text-white "
                  : " hover:bg-gray-100 text-gray-800"
                  }`}
                onClick={() => setSessionNumbersSelected([])}
              >
                All Sessions
              </button>
              {(sessionNumbersSelected.length > 1 || (sessionNumbersSelected.length === 1 && sessionNumbersSelected[0] !== Object.keys(ActivitesBySession).map((session) => session).slice(-1)[0])) && (
                <button
                  className={`px-2 py-1 rounded-md font-semibold ${sessionNumbersSelected.length > 0
                    ? "bg-gray-500 hover:bg-gray-600 text-white "
                    : " hover:bg-gray-100 text-gray-800"
                    }`}
                  onClick={() => setSessionNumbersSelected([])}
                >
                  {sessionNumbersSelected.length > 1 ? 'Sessions' : 'Session'} #{sessionNumbersSelected.join(", #")}
                </button>
              )}
            </div>
          </div>
          <div className="w-full flex-col space-y-2">
            {Object.keys(ActivitesBySession).reverse().map((session) => {
              return (
                <div key={session} hidden={sessionNumbersSelected.length > 0 ? !sessionNumbersSelected.includes(session) : false} className="w-full border-2 border-dashed border-gray-200 rounded-lg p-4">
                  <div className="flex justify-between"><div className="text-base font-semibold">Session #{session}</div> <div>{new Date(parseInt(ActivitesBySession[session][Object.keys(ActivitesBySession[session])[0]][0].event_summary.UnixStart) * 1000).toLocaleDateString()}</div></div>
                  <div className="text-left font-semibold align-top py-1 flex justify-between flex-col space-y-2 xl:flex-row xl:space-y-0 xl:space-x-2 xl:items-start">
                    {["Test", "Anti-Suppression", "Stereoacuity", "Vergence"].map((track, trackIndex) => {

                      const events = ActivitesBySession[session][track];

                      if (!events) return (<div key={track} className="flex-1 text-left p-2 rounded-lg flex flex-col space-y-1 h-fit"></div>)
                      return (
                        <div key={track} className="flex-1 text-left p-2 rounded-lg flex flex-col space-y-1 h-fit" style={{ background: TrackColors[track] }}>
                          {events.map((event, eventIndex) => {
                            return (<div key={event.event_summary.UnixStart} className="flex flex-col space-y-2">
                              {eventIndex === 0 && (
                                <div key={track} className="flex flex-col justify-center">
                                  <div className="flex items-center justify-between text-white">
                                    <div className="font-medium">{track}{track !== 'Test' && <>- Stage {parseFloat(event.stage)}</>}</div>
                                    <div className="text-[0.6rem] font-normal text-right leading-snug">{new Date(parseInt(event.event_summary.UnixStart) * 1000).toLocaleTimeString()}<br />- {new Date(parseInt(events[events.length - 1].event_summary.UnixStart + events[events.length - 1].event_summary.TimeLimit) * 1000).toLocaleTimeString()}</div>
                                  </div>

                                </div>
                              )}
                              <div className="flex pr-1 pl-0 shadow-sm rounded-md">
                                <div className="flex space-x-2 items-center">
                                  <div className="flex space-x-2 items-center py-1 pl-1 pr-1 h-full rounded-l-md text-white" style={{ background: mappings[event.activity_code].color }}>
                                    {Object.keys(ActivityCodeToPrimaryMetricMapping[event.activity_code]).length > 0 && <Icon icon={Object.values(ActivityCodeToPrimaryMetricMapping[event.activity_code])[0].icon} className="w-4 h-4" />}
                                  </div>

                                </div>
                                <div className="flex justify-between space-x-2 items-center rounded-r-md bg-white flex-1 px-2 py-0.5 relative">
                                  <div className="flex flex-col items-center justify-center">
                                    <div className="absolute bottom-0 left-1 text-[0.55rem] text-gray-500 font-normal leading-tight">
                                      {event.activity_code.includes("-dc") && ('Dichoptic')}
                                      {event.activity_code.includes("-sc") && ('Scaffold')}
                                    </div>
                                    <div className="font-medium truncate whitespace-nowrap flex-1 leading-tight">{mappings[event.activity_code].name.split("(")[0]}</div>
                                  </div>
                                  {ActivityCodeToPrimaryMetricMapping[event.activity_code] && [Object.keys(ActivityCodeToPrimaryMetricMapping[event.activity_code])[0]].map((metric) => (
                                    <div key={metric} className="flex flex-col space-x-2 items-end leading-loose">
                                      <div className="text-[0.6rem] text-gray-400">{splitCamelCaseToString(metric)}</div>
                                      <div className="text-xs">{ActivityCodeToPrimaryMetricMapping[event.activity_code][metric].display(event.event_summary.Metrics[metric])}</div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>);
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

    </>
  );
}
